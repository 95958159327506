<template>
  <div class="container-fluid" id="Verificacao">
    <LoadingPage v-if="!ready" />
    <div v-if="ready">
      <div v-if="ok">
        <img :src="logo" style="max-width: 400px;" />
        <h1 class="bold headline">
          <span>{{ this.nome }}</span>
        </h1>
        <h1 class="bold headline">
          <span class="superon-secoundary">:D</span>
        </h1>
        <h4 class="center">Seu {{ canal }} foi verificado com sucesso!</h4>
        <i :class="canalIcon" style="color:#32CD32"></i>
      </div>
      <div v-else>
        <LoadingPage v-if="ready" />
        <h1 class="bold headline">
          <span class="superon-primary">Erro na Verificação</span>
        </h1>
        <h1 class="bold headline">
          <span class="superon-secoundary">:(</span>
        </h1>
        <h4 class="center">Token Não Encontrado!!!</h4>
      </div>
    </div>
  </div>
</template>

<script>
import ApiWeb from '../../service/api-web'
import * as _ from 'lodash'
import LoadingPage from '@/components/LoadingPage'
import * as moment from 'moment'
export default {
  name: 'Verificacao',
  components: { LoadingPage },
  data: () => ({
    ready: false,
    ok: false,
    canal: '',
    idforncedor: '',
    idtokenconfirmacao: '',
    logo: '',
    nome: ''
  }),
  computed: {
    canalIcon() {
      return this.canal == 'email' ? 'fa fa-envelope' : 'fa fa-phone'
    }
  },
  created() {
    this.idforncedor = this.$route.params.idfornecedor || ''
    this.idtokenconfirmacao = this.$route.params.idtokenconfirmacao || ''
    ApiWeb.api
      .get(
        `/api/v4/verificacao?idtokenconfirmacao=${this.idtokenconfirmacao}&idfornecedor=${this.idforncedor}`
      )
      .then(resp => {
        this.canal = _.get(resp, 'data.canal', 'email')
        this.logo = _.get(resp, 'data.logo', '')
        this.nome = _.get(resp, 'data.nome', '')
        const dataverificacao = _.get(resp, 'data.dataverificacao', null)
        if (!dataverificacao) {
          this.ready = false
          ApiWeb.api
            .post(`/api/v4/verificacao`, {
              idtokenconfirmacao: this.idtokenconfirmacao,
              dataverificacao: moment()
            })
            .then(() => {
              this.ok = true
              this.ready = true
            })
            .catch(() => {
              this.ok = false
              this.ready = true
            })
        }
        this.ready = true
        this.ok = true
      })
      .catch(() => {
        this.ready = true
        this.ok = false
      })
    this.ready = true
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
body {
  background: rgba(128, 128, 128, 0.06);
}
.container-fluid {
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}
.headline {
  font-size: 2em;
  margin-top: 1em;
  text-align: center;
  margin-bottom: 1em;
}
.superon-primary {
  color: #979899;
}
.superon-secoundary {
  color: #e1a82d;
}
.bold {
  font-weight: bold;
}
</style>
